import { axios } from '@/utils/request'

/**
 * 根據流程定義查詢選項列表
 *
 * @author yubaoshan
 * @date 2020/8/11 15:41
 */
export function optionList (parameter) {
  return axios({
    url: '/flowableOption/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 編輯流程選項
 *
 * @author yubaoshan
 * @date 2020/8/11 15:41
 */
export function optionEdit (parameter) {
  return axios({
    url: '/flowableOption/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 查看流程選項
 *
 * @author yubaoshan
 * @date 2020/8/11 15:41
 */
export function optionDetail (parameter) {
  return axios({
    url: '/flowableOption/detail',
    method: 'get',
    params: parameter
  })
}
