//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { eventList, eventEdit, eventAdd, eventDelete } from '@/api/modular/flowable/eventManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { flowableDefinitionUserTasks } from '@/api/modular/flowable/defenitionManage'
import { flowableScriptList } from '@/api/modular/flowable/scriptManage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      tableLoading: false,
      Loading: false,
      columns: [],
      loadData: [],
      // 詳情參數
      visibleDetail: false,
      detailForm: this.$form.createForm(this),
      Detail: [],
      // 編輯參數
      visibleEdit: false,
      editForm: this.$form.createForm(this),
      // 新增參數
      visibleAdd: false,
      addForm: this.$form.createForm(this),
      // 查詢參數
      queryParam: {},
      // 公用
      nodeTypeData: [],
      typeData: [],
      recordData: [],
      actIdShow: false,
      actIdRequired: false,
      actData: [],
      scriptData: []
    }
  },
  methods: {
    /**
     * 打開頁面初始化
     */
    eventIndex (event) {
      this.recordData = event
      this.queryParam.processDefinitionId = event.id
      this.getEventList()
      this.addAction()
      this.getDictData()
    },
    /**
     * 初始化table信息
     */
    addAction () {
      this.columns.push(
        {
          title: '事件名稱',
          dataIndex: 'name'
        },
        {
          title: '事件節點類型',
          dataIndex: 'nodeType',
          scopedSlots: { customRender: 'nodeType' }
        },
        {
          title: '事件類型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '執行順序',
          dataIndex: 'execSort'
        },
        {
          title: '備註',
          dataIndex: 'remark'
        }
      )
      if (this.hasPerm('flowableEvent:detail') || this.hasPerm('flowableEvent:edit') || this.hasPerm('flowableEvent:delete')) {
        this.columns.push({
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }
    },
    /**
     * 獲取列表數據
     */
    getEventList () {
      this.tableLoading = true
      eventList(this.queryParam).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.loadData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 獲取字典參數
     */
    getDictData () {
      sysDictTypeDropDown({ code: 'event_node_type' }).then((res) => {
        this.nodeTypeData = res.data
      })
      sysDictTypeDropDown({ code: 'event_type' }).then((res) => {
        this.typeData = res.data
      })
    },
    /**
     * 事件節點類型翻譯
     */
    nodeTypeFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.nodeTypeData.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 事件類型翻譯
     */
    typeFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.typeData.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 關閉整個頁面
     */
    handleCancel () {
      this.loadData = []
      this.columns = []
    },
    /**
     * 打開詳情界面
     */
    handleDetail (detail) {
      this.Detail = detail
      this.visibleDetail = true
      setTimeout(() => {
        this.detailForm.setFieldsValue(
          {
            script: detail.script
          }
        )
      }, 100)
    },
    /**
     * 關閉詳情界面
     */
    handleCancelDetail () {
      this.visibleDetail = false
    },
    /**
     * 打開編輯界面
     */
    handleEdit (record) {
      this.visibleEdit = true
      this.flowableScriptList()
      setTimeout(() => {
        this.editForm.setFieldsValue(
          {
            id: record.id,
            processDefinitionId: record.processDefinitionId,
            name: record.name,
            nodeType: record.nodeType.toString(),
            type: record.type,
            script: record.script,
            execSort: record.execSort,
            remark: record.remark
          }
        )
      }, 100)
      if (record.nodeType.toString() === '2') {
        setTimeout(() => {
        this.editForm.setFieldsValue(
          {
            actId: record.actId,
            actName: record.actName
          }
        )
        }, 100)
      }
      this.nodeTypeClick(record.nodeType.toString(), 'edit')
    },
    /**
     * 關閉編輯界面
     */
    handleCancelEdit () {
      this.editForm.resetFields()
      this.visibleEdit = false
      this.actIdShow = false
      this.actIdRequired = false
    },
    /**
     * 編輯提交
     */
    handSubmitEdit () {
      const { editForm: { validateFields } } = this
      this.Loading = true
      validateFields((errors, values) => {
        if (!errors) {
          eventEdit(values).then((res) => {
            this.Loading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.getEventList()
              this.handleCancelEdit()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          })
        } else {
          this.Loading = false
        }
      })
    },
    /**
     * 打開新增界面
     */
    handleAdd () {
      this.visibleAdd = true
      this.flowableScriptList()
      setTimeout(() => {
        this.addForm.setFieldsValue({ processDefinitionId: this.recordData.id })
      }, 100)
    },
    /**
     * 關閉新增界面
     */
    handleCancelAdd () {
      this.addForm.resetFields()
      this.visibleAdd = false
    },
    /**
     * 新增提交
     */
    handSubmitAdd () {
      const { addForm: { validateFields } } = this
      this.Loading = true
      validateFields((errors, values) => {
        if (!errors) {
          eventAdd(values).then((res) => {
            this.Loading = false
            if (res.success) {
              this.$message.success('新增成功')
              this.getEventList()
              this.handleCancelAdd()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          })
        } else {
          this.Loading = false
        }
      })
    },
    /**
     * 刪除
     */
    handleDelete (record) {
      this.Loading = true
      eventDelete({ id: record.id }).then((res) => {
        this.Loading = false
        if (res.success) {
          this.$message.success('刪除成功')
          this.getEventList()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      })
    },
    /**
     * 事件節點單選框事件 , 編輯初始化選擇框事件
     */
    nodeTypeClick (code, type) {
       if (code === '2') {
         this.flowableDefinitionUserTasks()
         this.actIdShow = true
         this.actIdRequired = true
       } else {
         this.actIdShow = false
         this.actIdRequired = false
         if (type === 'add') {
           setTimeout(() => {
             this.addForm.setFieldsValue(
               {
                 actId: '',
                 actName: ''
               }
             )
           }, 100)
         }
         if (type === 'edit') {
           setTimeout(() => {
             this.editForm.setFieldsValue(
               {
                 actId: '',
                 actName: ''
               }
             )
           }, 100)
         }
       }
    },
    /**
     * 獲取活動節點下拉框數據
     */
    flowableDefinitionUserTasks () {
      this.Loading = true
      flowableDefinitionUserTasks({ id: this.recordData.id }).then((res) => {
        this.Loading = false
        this.actData = res.data
      })
    },
    /**
     * 活動節點下拉框事件
     */
    actIdSelectCange (id, type) {
      if (type === 'add') {
        this.addForm.setFieldsValue(
          {
            actName: this.actData.find(item => id === item.id).name
          }
        )
      }
      if (type === 'edit') {
        this.editForm.setFieldsValue(
          {
            actName: this.actData.find(item => id === item.id).name
          }
        )
      }
    },
    /**
     * 獲取待選腳本列表
     */
    flowableScriptList () {
      flowableScriptList().then((res) => {
        this.scriptData = res.data
      })
    },
    /**
     * 腳本下拉框事件
     */
    scriptSeleClick (id, type) {
      if (type === 'add') {
        this.addForm.setFieldsValue(
          {
            script: this.scriptData.find(item => id === item.id).content
          }
        )
      }
      if (type === 'edit') {
        this.editForm.setFieldsValue(
          {
            script: this.scriptData.find(item => id === item.id).content
          }
        )
      }
    }
  }
}
