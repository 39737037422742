//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { optionList, optionEdit } from '@/api/modular/flowable/optionManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      // 詳情參數
      visibleDetail: false,
      tableLoading: false,
      detailLoading: false,
      detailForm: this.$form.createForm(this),
      Detail: [],
      // 編輯參數
      visibleEdit: false,
      editLoading: false,
      editForm: this.$form.createForm(this),
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [],
      loadData: [],
      textData: []
    }
  },
  methods: {
    /**
     * 初始化界面
     */
    optionIndex (id) {
      this.queryParam.processDefinitionId = id
      this.getOptionList()
      this.addAction()
      this.getDictData()
    },
    /**
     * 初始化table
     */
    addAction () {
      this.columns.push(
        {
          title: '標題規則',
          dataIndex: 'title'
        },
        {
          title: '自動完成第一個任務',
          dataIndex: 'jumpFirst',
          scopedSlots: { customRender: 'jumpFirst' }
        },
        {
          title: '跳過相同處理人',
          dataIndex: 'smartComplete',
          scopedSlots: { customRender: 'smartComplete' }
        }
      )
      if (this.hasPerm('flowableOption:detail') || this.hasPerm('flowableOption:edit')) {
        this.columns.push({
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }
    },
    /**
     * 加載table數據
     */
    getOptionList () {
      this.tableLoading = true
      optionList(this.queryParam).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.loadData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 獲取字典數據
     */
    getDictData () {
      sysDictTypeDropDown({ code: 'yes_or_no' }).then((res) => {
        this.textData = res.data
      })
    },
    /**
     * 翻譯狀態
     */
    textFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.textData.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 清理數據
     */
    handleCancel () {
      this.loadData = []
      this.columns = []
    },
    /**
     * 打開詳情框
     */
    handleDetail (detail) {
      this.Detail = detail
      this.visibleDetail = true
    },
    /**
     * 關閉詳情框
     */
    handleCancelDetail () {
      this.visibleDetail = false
    },
    /**
     * 打開編輯框
     */
    handleEdit (record) {
      this.visibleEdit = true
      setTimeout(() => {
        this.editForm.setFieldsValue(
          {
            id: record.id,
            processDefinitionId: record.processDefinitionId,
            title: record.title,
            jumpFirst: record.jumpFirst,
            smartComplete: record.smartComplete
          }
        )
      }, 100)
    },
    /**
     * 關閉編輯框
     */
    handleCancelEdit () {
      this.editForm.resetFields()
      this.visibleEdit = false
    },
    /**
     * 編輯提交
     */
    editHandSubmit () {
      const { editForm: { validateFields } } = this
      this.editLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          optionEdit(values).then((res) => {
            this.editLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.getOptionList()
              this.handleCancelEdit()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          })
        } else {
          this.editLoading = false
        }
      })
    }
  }
}
